
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_l0dv0_11ev5_141",
  "header": "awsui_header_l0dv0_11ev5_178",
  "with-toolbar": "awsui_with-toolbar_l0dv0_11ev5_190",
  "header-link": "awsui_header-link_l0dv0_11ev5_194",
  "header-link--has-logo": "awsui_header-link--has-logo_l0dv0_11ev5_204",
  "header-link-text": "awsui_header-link-text_l0dv0_11ev5_204",
  "header-logo": "awsui_header-logo_l0dv0_11ev5_209",
  "header-logo--stretched": "awsui_header-logo--stretched_l0dv0_11ev5_215",
  "items-control": "awsui_items-control_l0dv0_11ev5_220",
  "list-container": "awsui_list-container_l0dv0_11ev5_225",
  "list": "awsui_list_l0dv0_11ev5_225",
  "list-variant-root": "awsui_list-variant-root_l0dv0_11ev5_240",
  "list-variant-root--last": "awsui_list-variant-root--last_l0dv0_11ev5_247",
  "list-variant-expandable-link-group": "awsui_list-variant-expandable-link-group_l0dv0_11ev5_251",
  "list-item": "awsui_list-item_l0dv0_11ev5_255",
  "section": "awsui_section_l0dv0_11ev5_263",
  "expandable-link-group": "awsui_expandable-link-group_l0dv0_11ev5_264",
  "section--no-ident": "awsui_section--no-ident_l0dv0_11ev5_267",
  "expandable-link-group--no-ident": "awsui_expandable-link-group--no-ident_l0dv0_11ev5_268",
  "refresh": "awsui_refresh_l0dv0_11ev5_276",
  "list-variant-section-group": "awsui_list-variant-section-group_l0dv0_11ev5_284",
  "section-group": "awsui_section-group_l0dv0_11ev5_291",
  "section-group-title": "awsui_section-group-title_l0dv0_11ev5_302",
  "link": "awsui_link_l0dv0_11ev5_306",
  "link-active": "awsui_link-active_l0dv0_11ev5_315",
  "info": "awsui_info_l0dv0_11ev5_352",
  "external-icon": "awsui_external-icon_l0dv0_11ev5_356",
  "divider": "awsui_divider_l0dv0_11ev5_360",
  "divider-default": "awsui_divider-default_l0dv0_11ev5_365",
  "divider-header": "awsui_divider-header_l0dv0_11ev5_371"
};
  