import React from 'react'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadLink } from '../common/RadLink'
import { formatDate } from '../common/utilities'
import { RadBadgeGroup } from '../common/RadBadgeGroup'

export function ProjectDetailContent ({ project, setSelectedEntity }) {
  if (project != null) {
    return (
      <RadSpaceBetween size='l'>
        <RadColumnLayout columns={4} borders='vertical'>
          <div>
            <RadBox variant='awsui-key-label'>
              Project Highlights
            </RadBox>
            <div>{project.deliverables ?? '-'}</div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              DASH Funding Program
            </RadBox>
            <div>{project.dashFundingProgram?.name ?? '-'}</div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Funding Start Date
            </RadBox>
            <div>{project.startDate != null ? formatDate(project.startDate) : '-'}</div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Funding End Date
            </RadBox>
            <div>{project.endDate != null ? formatDate(project.endDate) : '-'}</div>
          </div>
          <div>
            <RadSpaceBetween size='xxs'>
              <RadBox variant='awsui-key-label'>
                Contacts
              </RadBox>
              {project.contacts?.length > 0
                ? project.contacts.map((x) => <div key={x.id}>{x.name}</div>)
                : '-'}
            </RadSpaceBetween>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Organizations
            </RadBox>
            <div>{project.organizations?.length > 0
              ? project.organizations.map((x) =>
                <div key={x.id}>
                  {setSelectedEntity == null && <RadLink href={`/organization/${x.organization.id}`}>{x.organization?.name}</RadLink>}
                  {setSelectedEntity != null &&
                    <RadButton
                      onClick={() => setSelectedEntity({ type: 'organization', id: x.organization.id })}
                      className='font-weight-400'
                      variant='inline-link'
                    >
                      {x.organization?.name}
                    </RadButton>}
                </div>
              )
              : '-'}
            </div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Sectors
            </RadBox>
            <div>
              <RadBadgeGroup
                parentId={project.id}
                items={project.sectors}
                mapFunc={x => ({ key: x.id, name: x.sector?.name })}
              />
            </div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Tags
            </RadBox>
            <div>
              <RadBadgeGroup
                parentId={project.id}
                items={project.tags}
                mapFunc={x => ({ key: x.id, name: x.tag.name })}
              />
            </div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Priority Areas
            </RadBox>
            <div>{project.targetAreas?.length > 0 ? project.targetAreas.map((x) => <div key={x.id}>{x.targetArea.name}</div>) : '-'}</div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Priority Populations
            </RadBox>
            <div>{project.targetPopulations?.length > 0 ? project.targetPopulations.map((x) => <div key={x.id}>{x.targetPopulation.name}</div>) : '-'}</div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Project Types
            </RadBox>
            <div>{project.useCases?.length > 0 ? project.useCases.map((x) => <div key={x.id}>{x.useCase?.name}</div>) : '-'}</div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Collaboratives
            </RadBox>
            <div>{project.collaboratives?.length > 0
              ? project.collaboratives.map((x) =>
                <div key={'collaborative-' + x.id}>
                  {setSelectedEntity == null && <RadLink href={`/collaborative/${x.collaborative.id}`}>{x.collaborative.name}</RadLink>}
                  {setSelectedEntity != null &&
                    <RadButton
                      onClick={() => setSelectedEntity({ type: 'collaborative', id: x.collaborative.id })}
                      className='font-weight-400'
                      variant='inline-link'
                    >
                      {x.collaborative.name}
                    </RadButton>}
                </div>
              )
              : '-'}
            </div>
          </div>
        </RadColumnLayout>
        <div>
          <RadBox variant='awsui-key-label'>
            Resources
          </RadBox>
          <div>{project.resources?.length > 0
            ? project.resources.map((x) =>
              <div key={x.id}>
                {setSelectedEntity == null && <RadLink href={`/resource/${x.resource.id}`}>{x.resource?.title}</RadLink>}
                {setSelectedEntity != null &&
                  <RadButton
                    onClick={() => setSelectedEntity({ type: 'resource', id: x.resource.id })}
                    className='font-weight-400'
                    variant='inline-link'
                  >
                    {x.resource?.title}
                  </RadButton>}
              </div>)
            : '-'}
          </div>
        </div>
      </RadSpaceBetween>
    )
  }
}
