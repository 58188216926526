
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_dm8gx_1s4hp_149",
  "has-items": "awsui_has-items_dm8gx_1s4hp_183",
  "no-padding": "awsui_no-padding_dm8gx_1s4hp_183",
  "dismiss-button": "awsui_dismiss-button_dm8gx_1s4hp_187",
  "token": "awsui_token_dm8gx_1s4hp_229",
  "token-box": "awsui_token-box_dm8gx_1s4hp_236",
  "token-box-error": "awsui_token-box-error_dm8gx_1s4hp_255",
  "token-box-warning": "awsui_token-box-warning_dm8gx_1s4hp_266",
  "token-box-readonly": "awsui_token-box-readonly_dm8gx_1s4hp_277",
  "token-box-disabled": "awsui_token-box-disabled_dm8gx_1s4hp_291"
};
  