
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_7nfqu_osznj_149",
  "has-adaptive-widths-default": "awsui_has-adaptive-widths-default_7nfqu_osznj_198",
  "has-adaptive-widths-dashboard": "awsui_has-adaptive-widths-dashboard_7nfqu_osznj_213",
  "navigation": "awsui_navigation_7nfqu_osznj_228",
  "tools": "awsui_tools_7nfqu_osznj_229",
  "global-tools": "awsui_global-tools_7nfqu_osznj_230",
  "split-panel-side": "awsui_split-panel-side_7nfqu_osznj_279",
  "split-panel-bottom": "awsui_split-panel-bottom_7nfqu_osznj_286",
  "panel-hidden": "awsui_panel-hidden_7nfqu_osznj_299",
  "toolbar-container": "awsui_toolbar-container_7nfqu_osznj_309",
  "notifications-container": "awsui_notifications-container_7nfqu_osznj_315",
  "main-landmark": "awsui_main-landmark_7nfqu_osznj_322",
  "main": "awsui_main_7nfqu_osznj_322",
  "main-disable-paddings": "awsui_main-disable-paddings_7nfqu_osznj_331",
  "content-header": "awsui_content-header_7nfqu_osznj_341",
  "content": "awsui_content_7nfqu_osznj_341",
  "unfocusable-mobile": "awsui_unfocusable-mobile_7nfqu_osznj_351"
};
  