
    import './styles.scoped.css';
    export default {
  "universal-toolbar": "awsui_universal-toolbar_1kzri_18for_141",
  "disable-body-scroll": "awsui_disable-body-scroll_1kzri_18for_162",
  "toolbar-hidden": "awsui_toolbar-hidden_1kzri_18for_165",
  "toolbar-container": "awsui_toolbar-container_1kzri_18for_168",
  "universal-toolbar-nav": "awsui_universal-toolbar-nav_1kzri_18for_177",
  "universal-toolbar-breadcrumbs": "awsui_universal-toolbar-breadcrumbs_1kzri_18for_181",
  "universal-toolbar-drawers": "awsui_universal-toolbar-drawers_1kzri_18for_186",
  "drawers-desktop-triggers-container": "awsui_drawers-desktop-triggers-container_1kzri_18for_194",
  "drawers-mobile-triggers-container": "awsui_drawers-mobile-triggers-container_1kzri_18for_195",
  "drawers-trigger-content": "awsui_drawers-trigger-content_1kzri_18for_241",
  "group-divider": "awsui_group-divider_1kzri_18for_250",
  "drawers-trigger": "awsui_drawers-trigger_1kzri_18for_241",
  "block-body-scroll": "awsui_block-body-scroll_1kzri_18for_263"
};
  