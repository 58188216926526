
    import './styles.scoped.css';
    export default {
  "alert": "awsui_alert_mx3cw_1ng59_189",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_mx3cw_1ng59_1",
  "root": "awsui_root_mx3cw_1ng59_211",
  "hidden": "awsui_hidden_mx3cw_1ng59_249",
  "with-dismiss": "awsui_with-dismiss_mx3cw_1ng59_299",
  "with-action": "awsui_with-action_mx3cw_1ng59_299",
  "breakpoint-default": "awsui_breakpoint-default_mx3cw_1ng59_305",
  "header": "awsui_header_mx3cw_1ng59_316",
  "header-replacement": "awsui_header-replacement_mx3cw_1ng59_317",
  "action": "awsui_action_mx3cw_1ng59_321",
  "action-slot": "awsui_action-slot_mx3cw_1ng59_326",
  "action-button": "awsui_action-button_mx3cw_1ng59_327",
  "alert-focus-wrapper": "awsui_alert-focus-wrapper_mx3cw_1ng59_331",
  "text": "awsui_text_mx3cw_1ng59_360",
  "icon": "awsui_icon_mx3cw_1ng59_367",
  "message": "awsui_message_mx3cw_1ng59_370",
  "icon-size-medium": "awsui_icon-size-medium_mx3cw_1ng59_378",
  "icon-size-big": "awsui_icon-size-big_mx3cw_1ng59_381",
  "icon-size-normal": "awsui_icon-size-normal_mx3cw_1ng59_384",
  "content": "awsui_content_mx3cw_1ng59_388",
  "content-replacement": "awsui_content-replacement_mx3cw_1ng59_389",
  "dismiss": "awsui_dismiss_mx3cw_1ng59_393",
  "dismiss-button": "awsui_dismiss-button_mx3cw_1ng59_398",
  "type-error": "awsui_type-error_mx3cw_1ng59_402",
  "type-warning": "awsui_type-warning_mx3cw_1ng59_410",
  "type-success": "awsui_type-success_mx3cw_1ng59_418",
  "type-info": "awsui_type-info_mx3cw_1ng59_426"
};
  