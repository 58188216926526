
    import './styles.scoped.css';
    export default {
  "drawer": "awsui_drawer_12i0j_qj758_185",
  "legacy": "awsui_legacy_12i0j_qj758_200",
  "last-opened": "awsui_last-opened_12i0j_qj758_205",
  "drawer-global": "awsui_drawer-global_12i0j_qj758_210",
  "drawer-hidden": "awsui_drawer-hidden_12i0j_qj758_217",
  "drawer-content-container": "awsui_drawer-content-container_12i0j_qj758_220",
  "drawer-close-button": "awsui_drawer-close-button_12i0j_qj758_228",
  "drawer-content": "awsui_drawer-content_12i0j_qj758_220",
  "drawer-content-hidden": "awsui_drawer-content-hidden_12i0j_qj758_237",
  "drawer-slider": "awsui_drawer-slider_12i0j_qj758_240"
};
  